import React from 'react';
import PropTypes from 'prop-types';
import {merge} from '../../../lib/object';
import {Trans, t} from '@lingui/macro';
import {Button, Divider, Form, Input, Radio} from 'antd';
import InputQuantity from '../../project/InputQuantity';
import { createFetchVehicleAdd, createFetchVehicleEdit} from '../../../backend/apiCalls';
import {formItemLayout, tailFormItemLayout, mandatoryRule} from '../../../constants/form';
import {Vehicle} from '../../../constants/propTypesDefinitions';
import EmployeesSelect from '../../project/EmployeesSelect';
import DatePicker from '../../general/DatePicker';
import InputText from '../../general/InputText';
import FuelTypeSelect from '../../project/FuelTypeSelect';
import InputNumber from '../../general/InputNumber';
import InputPrice from '../../project/InputPrice';
const FormItem = Form.Item;

/**
 * @dusan
 */

class VehicleForm extends React.PureComponent {
    static propTypes = {
        onClose: PropTypes.func.isRequired,
        onSubmitFinish: PropTypes.func.isRequired,
        values: PropTypes.shape({
            vehicle: Vehicle,
            isEdit: PropTypes.bool.isRequired,
        }).isRequired,
    };

    constructor(props) {
        super(props);
        this.state = {
            loading: false,
        };
    }

    componentDidMount() {
        setTimeout(() => {
            if(this.first != null && this.first.focus != null)
                this.first.focus();
        }, 0)
    }

    handleSubmit = (e) => {
        const {onSubmitFinish, values} = this.props;
        e.preventDefault();
        this.props.form.validateFieldsAndScroll((err, formValues) => {
            if (!err) {
                this.setState({loading: true});
                const {isEdit, vehicle ={}} = values;
                const fetchFunction = isEdit ? createFetchVehicleEdit() : createFetchVehicleAdd();
                const checkedValues = isEdit ?
                    merge(formValues, {id: vehicle.id}) : formValues;
                fetchFunction(checkedValues).then((response) => {
                    this.setState({
                        loading: false,
                    });
                    onSubmitFinish(response);
                });
            }
        });
    };

    render() {
        const {loading} = this.state;
        const {values: {isEdit, vehicle = {}}, onClose} = this.props;
        const {getFieldDecorator} = this.props.form;
        return <Form onSubmit={this.handleSubmit}>
            <FormItem
                {...formItemLayout}
                label={<Trans>EČV vozidla</Trans>}
            >
                {getFieldDecorator('name', {rules: [mandatoryRule], initialValue: vehicle.name})(
                    <Input ref={node => this.first = node}/>
                )}
            </FormItem>
            <FormItem
                {...formItemLayout}
                label={<Trans>Popis</Trans>}
            >
                {getFieldDecorator('description', {initialValue: vehicle.description})(
                    <Input/>
                )}
            </FormItem>
            <FormItem
                {...formItemLayout}
                label={<Trans>Zodpovedná osoba</Trans>}
            >
                {getFieldDecorator('id_user', {rules: [mandatoryRule], initialValue: vehicle.id_user})(
                    <EmployeesSelect/>
                )}
            </FormItem>
            <FormItem
                {...formItemLayout}
                label={<Trans>Palivo</Trans>}
            >
                {getFieldDecorator('id_fuel_type', {initialValue: vehicle.id_fuel_type})(
                    <FuelTypeSelect/>
                )}
            </FormItem>
            <FormItem
                {...formItemLayout}
                label={<Trans>Spotreba mesto</Trans>}
            >
                {getFieldDecorator('fuel_consumption_local', {rules: [mandatoryRule], initialValue: vehicle.fuel_consumption_local})(
                    <InputQuantity 
                        step={0.01}
                        unit="L&nbsp;/&nbsp;100&nbsp;km"
                    />
                )}
            </FormItem>
            <FormItem
                {...formItemLayout}
                label={<Trans>Spotreba mimo mesto</Trans>}
            >
                {getFieldDecorator('fuel_consumption_away', {rules: [mandatoryRule], initialValue: vehicle.fuel_consumption_away})(
                    <InputQuantity 
                        step={0.01}
                        unit="L&nbsp;/&nbsp;100&nbsp;km"
                    />
                )}
            </FormItem>
            <FormItem
                {...formItemLayout}
                label={<Trans>Objem nádrže</Trans>}
            >
                {getFieldDecorator('fuel_capacity', {initialValue: vehicle.fuel_capacity})(
                    <InputQuantity 
                        step={0.01}
                        unit="L"
                    />
                )}
            </FormItem>
            <FormItem
                {...formItemLayout}
                label={<Trans>Uzatvorené do</Trans>}
            >
                {getFieldDecorator('closed_until', {initialValue: vehicle.closed_until})(
                    <DatePicker/>
                )}
            </FormItem>
            <FormItem
                {...formItemLayout}
                label={<Trans>Je aktívne</Trans>}
            >
                {getFieldDecorator('is_active', {initialValue: vehicle.is_active != null ? vehicle.is_active : 1})(
                    <Radio.Group>
                        <Radio.Button value={1}><Trans>Áno</Trans></Radio.Button>
                        <Radio.Button value={0}><Trans>Nie</Trans></Radio.Button>
                    </Radio.Group>
                )}
            </FormItem>
            <Divider orientation="left"><Trans>Počiatočný stav</Trans></Divider>
            <FormItem
                {...formItemLayout}
                label={<Trans>Počítadlo kilometrov</Trans>}
            >
                {getFieldDecorator('start_km', {rules: [mandatoryRule], initialValue: vehicle.start_km != null ? vehicle.start_km : 0})(
                    <InputQuantity 
                        step={1}
                        unit="km"
                    />
                )}
            </FormItem>
            <FormItem
                {...formItemLayout}
                label={<Trans>Zostatok v nádrži</Trans>}
            >
                {getFieldDecorator('start_fuel_quantity', {rules: [mandatoryRule], initialValue: vehicle.start_fuel_quantity != null ? vehicle.start_fuel_quantity : 0})(
                    <InputQuantity 
                        step={0.01}
                        unit="L"
                    />
                )}
            </FormItem>
            <FormItem
                {...formItemLayout}
                label={<Trans>Hodnota nádrže</Trans>}
            >
                {getFieldDecorator('start_fuel_value', {rules: [mandatoryRule], initialValue: vehicle.start_fuel_value != null ? vehicle.start_fuel_value : 0})(
                    <InputPrice 
                        step={0.01}
                    />
                )}
            </FormItem>
            <FormItem
                {...formItemLayout}
                label={<Trans>Platnosť STK</Trans>}
            >
                {getFieldDecorator('stk_valid_until', {initialValue: vehicle.stk_valid_until})(
                    <DatePicker/>
                )}
            </FormItem>
            <FormItem
                {...formItemLayout}
                label={<Trans>Platnosť EK</Trans>}
            >
                {getFieldDecorator('ek_valid_until', {initialValue: vehicle.ek_valid_until})(
                    <DatePicker/>
                )}
            </FormItem>
            <FormItem
                {...formItemLayout}
                label={<Trans>Platnosť diaľničnej známky</Trans>}
            >
                {getFieldDecorator('toll_valid_until', {initialValue: vehicle.toll_valid_until})(
                    <DatePicker/>
                )}
            </FormItem>
            <FormItem
                {...formItemLayout}
                label={<Trans>Dátum výroby</Trans>}
            >
                {getFieldDecorator('manufactured_at', {initialValue: vehicle.manufactured_at})(
                    <DatePicker/>
                )}
            </FormItem>
            <FormItem
                {...formItemLayout}
                label={<Trans>Emisie</Trans>}
            >
                {getFieldDecorator('emission', {initialValue: vehicle.emission})(
                    <InputText/>
                )}
            </FormItem>
            
            <FormItem
                {...formItemLayout}
                label={<Trans>Stav</Trans>}
            >
                {getFieldDecorator('id_status', {initialValue: vehicle.id_status})(
                    <InputNumber/>
                )}
            </FormItem>
            <FormItem {...tailFormItemLayout}>
                <Button key="back" onClick={onClose}><Trans>Zrušiť</Trans></Button>
                <Button htmlType="submit" key="submit" type="primary" loading={loading} onClick={this.handleSubmit}>
                    {isEdit ? <Trans>Upraviť</Trans> : <Trans>Vytvoriť</Trans>}
                </Button>
            </FormItem>
        </Form>
    }
}

export default Form.create()(VehicleForm);